<script lang="ts">
  import { InteractiveElement } from "../InteractiveElement";
  import type { BasicCardV2Props } from "./BasicCardV2Props";
  import { currentZIndex } from "../../utils/currentZIndex";

  let {
    href = $bindable(undefined),
    base64Href = $bindable(undefined),
    rel = undefined,
    target = undefined,
    asButton = false,
    children,
    ocAriaLabel = undefined,
    Host,
    ...rest
  }: BasicCardV2Props = $props();
</script>

<article class="card">
  <InteractiveElement
    {Host}
    {asButton}
    bind:href
    bind:base64Href
    {rel}
    {target}
    aria-label={ocAriaLabel}
    {children}
    {...rest}
    style="--z-index:{currentZIndex(Host)}"
    class={`card__interactive ${rest.class ?? ""}`}
  />
</article>

<style lang="scss" global>
  @use "@otto-ec/design-tokens/component" as tokens;
  @use "@otto-ec/otto-components-utils/scss/mixins";
  @use "../../../components/floating-focus/v1/FloatingFocusV1.shared" as floating-focus;

  .card {
    display: block;
    width: 100%;
    height: 100%;
  }

  .card__interactive {
    all: unset;
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    background-color: tokens.$oc-component-card-default-background-color;
    border-radius: tokens.$oc-component-card-100-border-radius;
    overflow: hidden;
    outline: none;
    outline-offset: 0; /*                                        */

    ::slotted([slot="primary-action"]) {
      all: unset;
      position: absolute;
      display: block;
      inset: 0;
      cursor: pointer;
      z-index: calc(var(--z-index) + 1);

      @include floating-focus.full-bleed();
    }

    &.-interactive {
      cursor: pointer;
    }

    /*                  */
    @media (hover: hover) {
      &.-interactive:hover,
      ::slotted([slot="primary-action"]:hover) {
        &:after {
          content: "";
          position: absolute;
          inset: 0;
          border-radius: inherit;
          pointer-events: none;
          background-color: tokens.$oc-component-card-overlay-hover;
        }
      }
    }

    /*                  */
    &.-interactive:active,
    ::slotted([slot="primary-action"]:active) {
      &:after {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: inherit;
        pointer-events: none;
        background-color: tokens.$oc-component-card-overlay-active;
      }
    }
  }
</style>

<svelte:options
  customElement={{
    tag: "oc-button-v1",
    /*                                            */
    extend: window.__components.extend({
      formAssociated: true,
    }),
    props: {
      base64Href: { type: "String", attribute: "base64-href" },
      disabled: { type: "Boolean", reflect: true },
      fillParent: { type: "Boolean", attribute: "fill-parent", reflect: true },
      fitContent: { type: "Boolean", attribute: "fit-content", reflect: true },
      formAction: { type: "String", attribute: "formaction", reflect: true },
      formMethod: { type: "String", attribute: "formmethod", reflect: true },
      href: { type: "String" },
      iconTypeLeft: { type: "String", attribute: "icon-type-left" },
      iconTypeRight: { type: "String", attribute: "icon-type-right" },
      loading: { type: "Boolean" },
      ocAriaLabel: { type: "String", attribute: "oc-aria-label" },
      rel: { type: "String" },
      size: { type: "String", reflect: true },
      target: { type: "String" },
      type: { type: "String" },
      variant: { type: "String" },
    },
  }}
/>

<script lang="ts">
  /*                                            */
  import { onMount } from "svelte";
  /*                                            */
  import { fade } from "svelte/transition";
  import { InteractiveElement } from "../../../common/components/InteractiveElement";

  import { getDefaultSubmitter } from "../../../common/utils/form/form";
  import { track } from "../../../common/utils/track";

  import type { Props } from "./ButtonV1.types";
  import { requestSubmit } from "./requestSubmit";

  let {
    variant = "primary",
    size = "100",
    iconTypeLeft = undefined,
    iconTypeRight = undefined,
    fitContent = false,
    fillParent = false,
    disabled = false,
    type = undefined,
    loading = false,
    ocAriaLabel = undefined,
    href = undefined,
    base64Href = undefined,
    formMethod = undefined,
    formAction = undefined,
    rel = undefined,
    target = undefined,
    internals,
  } = $props<Props & { internals: ElementInternals }>();

  const Host = $host<HTMLOcButtonV1Element>();

  const hasIconRight = $derived(iconTypeRight || loading);

  let button = $state<HTMLElement>();

  function isFirstChildAnchor(node: Node): node is HTMLAnchorElement {
    return node && node.nodeType === Node.ELEMENT_NODE && (node as HTMLElement).tagName === "A";
  }

  function tooltipContent(): string {
    let content = "";

    if (isFirstChildAnchor(Host.children[0])) {
      content = Host.children[0].innerHTML;
    } else {
      content = Host.innerHTML;
    }

    return `<div aria-hidden="true" slot="tooltip-content">${content}</div>`;
  }

  let innerSpan = $state<HTMLElement>();
  let innerSpanClientWidth = $state<number>(0);

  let isSpanOverflowing = $state(false);
  let isAnchorOverflowing = $state(false);

  $effect(() => {
    track(innerSpan, innerSpanClientWidth);

    requestAnimationFrame(() => {
      isSpanOverflowing = !!(innerSpan && innerSpan?.scrollWidth > innerSpanClientWidth);
    });
  });

  $effect(() => {
    track(innerSpan, innerSpanClientWidth);

    requestAnimationFrame(() => {
      isAnchorOverflowing =
        isFirstChildAnchor(Host.children[0]) && Host.children[0].scrollWidth > innerSpanClientWidth;
    });
  });

  Host.addEventListener("click", (event) => {
    if (loading) {
      event.stopPropagation();
      event.preventDefault();
      return;
    }

    if (type === "submit") {
      event.preventDefault();
      requestSubmit(internals.form, button!);
    } else if (type === "reset") {
      event.preventDefault();
      internals.form?.reset();
    }
  });

  onMount(() => {
    const formEnterHandler = (event: KeyboardEvent) => {
      /*                                                  */
      if (event.defaultPrevented) return;

      /*                    */
      if (event.key !== "Enter" || type !== "submit") return;

      const tagName = (event.target as HTMLElement | null)?.tagName;

      /*                              */
      if (tagName === "TEXTAREA") return;

      /*                          */
      /*                                       */
      if (tagName?.startsWith("OC-")) return;

      /*                                                                       */
      if (!Array.from(internals.form?.elements || []).some((e) => e === event.target)) return;

      /*                                                           */
      if (Host !== getDefaultSubmitter(internals.form)) return;

      event.preventDefault();
      /*                                                                               */
      /*                                                */
      Host.click();
    };
    internals.form?.addEventListener("keydown", formEnterHandler);
    return () => {
      internals.form?.removeEventListener("keydown", formEnterHandler);
    };
  });

  let isFitContent = $derived((fitContent && size === "100") || (!fillParent && size === "50"));
</script>

<!-- eslint-disable svelte/no-at-html-tags -->
{#snippet interactiveElement()}
  <InteractiveElement
    {Host}
    asButton={true}
    bind:thisElement={button}
    bind:href
    bind:base64Href
    {disabled}
    {type}
    aria-label={ocAriaLabel}
    class={`button button--variant-${variant} button--size-${size} ${isFitContent ? "button--fit-content" : ""} ${iconTypeLeft ? "button--has-icon-left" : ""} ${hasIconRight ? "button--has-icon-right" : ""} ${disabled ? "button--disabled" : ""} ${loading ? "button--is-loading" : ""}`}
    aria-disabled={loading || disabled}
    formmethod={formMethod}
    formaction={formAction}
    {rel}
    {target}
  >
    {#snippet children(onslotchange)}
      {#if iconTypeLeft}
        <oc-icon-v1 class="button__icon button__icon--left" type={iconTypeLeft} {size}></oc-icon-v1>
      {/if}

      <span bind:this={innerSpan} bind:clientWidth={innerSpanClientWidth}>
        <slot {onslotchange} />
      </span>

      {#if loading}
        <oc-spinner-v1
          in:fade={{ duration: 200 }}
          class="button__icon button__icon--right"
          variant={variant === "secondary" || variant === "tertiary" ? "default" : "inverted"}
          {size}
        ></oc-spinner-v1>
      {:else if iconTypeRight}
        <oc-icon-v1 class="button__icon button__icon--right" type={iconTypeRight} {size}
        ></oc-icon-v1>
      {/if}
    {/snippet}
  </InteractiveElement>
{/snippet}

{#if isSpanOverflowing || isAnchorOverflowing}
  <oc-tooltip-v1 class:tooltip--fit-content={isFitContent} position="top"
    >{@render interactiveElement()} {@html tooltipContent()}</oc-tooltip-v1
  >
{:else}
  {@render interactiveElement()}
{/if}

<style lang="scss" global>
  @use "@otto-ec/design-tokens/component" as tokens;
  @use "@otto-ec/otto-components-utils/scss/mixins";
  @use "../../floating-focus/v1/FloatingFocusV1.shared" as floating-focus;

  @mixin overflowEllipsis() {
    overflow: hidden;
    text-overflow: ellipsis;
    user-select: none;
    white-space: nowrap;
  }

  $oc-component-button-100-span-spacing: calc(
    tokens.$oc-component-button-100-icon-size + tokens.$oc-component-button-100-icon-spacing-x
  );
  $oc-component-button-50-span-spacing: calc(
    tokens.$oc-component-button-50-icon-size + tokens.$oc-component-button-50-icon-spacing-x
  );

  :host {
    @include mixins.no-tap-highlight();
    display: block;
    position: relative;
  }

  :host([fit-content]),
  :host([size="50"]) {
    width: fit-content;
  }

  :host([fill-parent]) {
    width: 100%;
  }

  /*                  */
  ::slotted(a) {
    display: block;
    text-decoration: inherit;
    color: inherit;
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
    outline: none;
    outline-offset: 0; /*                                        */
    font: inherit;

    @include overflowEllipsis();

    /*                    */
    @include floating-focus.full-bleed();

    /*                                           */
    &:before {
      content: "";
      position: absolute;
      inset: 0;
    }
  }

  /*                                   */
  .button {
    align-items: center;
    border-radius: tokens.$oc-component-button-100-border-radius;
    border: none;
    box-sizing: border-box;
    display: inline-flex;
    font: tokens.$oc-component-button-100-primary-font;
    font-weight: tokens.$oc-component-button-100-primary-font-weight;
    justify-content: center;
    margin: 0;
    outline: none;
    outline-offset: 0;
    padding: tokens.$oc-component-button-100-spacing-y
      tokens.$oc-component-button-100-default-spacing-x;
    position: relative;
    text-decoration: none;
    width: 100%;

    &--has-icon-left,
    &--has-icon-right {
      padding: tokens.$oc-component-button-100-spacing-y
        tokens.$oc-component-button-100-icon-spacing-x;

      > span {
        padding: 0 $oc-component-button-100-span-spacing;
      }
    }

    &--fit-content {
      width: fit-content;

      &.button--has-icon-left {
        > span {
          padding: 0 tokens.$oc-component-button-100-icon-spacing-x 0
            $oc-component-button-100-span-spacing;
        }
      }

      &.button--has-icon-right {
        > span {
          padding: 0 $oc-component-button-100-span-spacing 0
            tokens.$oc-component-button-100-icon-spacing-x;
        }
      }

      &.button--has-icon-left.button--has-icon-right {
        > span {
          padding: 0 $oc-component-button-100-span-spacing;
        }
      }
    }

    > .button__icon {
      height: tokens.$oc-component-button-100-icon-size;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: tokens.$oc-component-button-100-icon-size;

      &--left {
        left: tokens.$oc-component-button-100-icon-spacing-x;
      }

      &--right {
        left: auto;
        right: tokens.$oc-component-button-100-icon-spacing-x;
      }
    }

    > span {
      @include overflowEllipsis();
    }

    &--variant-primary {
      background-color: tokens.$oc-component-button-primary-background-color;
      color: tokens.$oc-component-button-primary-text-color;

      > .button__icon {
        --color: #{tokens.$oc-component-button-primary-icon-color};
      }

      &:not(.button--is-loading) {
        @media (hover: hover) {
          &:hover:not(:disabled) {
            background-color: tokens.$oc-component-button-primary-background-color-hover;
            cursor: pointer;
          }
        }

        &:active:not(:disabled) {
          background-color: tokens.$oc-component-button-primary-background-color-active;
          cursor: pointer;
        }
      }
    }

    &--variant-secondary {
      background-color: tokens.$oc-component-button-secondary-background-color;
      color: tokens.$oc-component-button-secondary-text-color;
      font: tokens.$oc-component-button-100-secondary-font;

      > .button__icon {
        --color: #{tokens.$oc-component-button-secondary-icon-color};
      }

      &:not(.button--is-loading) {
        @media (hover: hover) {
          &:hover:not(:disabled) {
            background-color: tokens.$oc-component-button-secondary-background-color-hover;
            cursor: pointer;
          }
        }

        &:active:not(:disabled) {
          background-color: tokens.$oc-component-button-secondary-background-color-active;
          cursor: pointer;
        }
      }
    }

    &--variant-tertiary {
      background-color: tokens.$oc-component-button-tertiary-background-color;
      color: tokens.$oc-component-button-tertiary-text-color;
      font: tokens.$oc-component-button-100-tertiary-font;

      > .button__icon {
        --color: #{tokens.$oc-component-button-tertiary-icon-color};
      }

      &:not(.button--is-loading) {
        @media (hover: hover) {
          &:hover:not(:disabled) {
            background-color: tokens.$oc-component-button-tertiary-background-color-hover;
            cursor: pointer;
          }
        }

        &:active:not(:disabled) {
          background-color: tokens.$oc-component-button-tertiary-background-color-active;
          cursor: pointer;
        }
      }
    }

    &:disabled {
      background-color: tokens.$oc-component-button-disabled-background-color;
      color: tokens.$oc-component-button-disabled-text-color;
      font: tokens.$oc-component-button-100-disabled-font;

      > .button__icon {
        --color: #{tokens.$oc-component-button-disabled-icon-color};
      }
    }

    &--size-50 {
      border-radius: tokens.$oc-component-button-50-border-radius;
      padding: tokens.$oc-component-button-50-spacing-y
        tokens.$oc-component-button-50-default-spacing-x;

      &.button--has-icon-left {
        padding-top: tokens.$oc-component-button-50-spacing-y;
        padding-right: tokens.$oc-component-button-50-default-spacing-x;
        padding-bottom: tokens.$oc-component-button-50-spacing-y;
        padding-left: calc(tokens.$oc-component-button-50-default-spacing-x / 2);

        > span {
          padding: 0 0 0 $oc-component-button-50-span-spacing;
        }
      }

      &.button--has-icon-right {
        padding-top: tokens.$oc-component-button-50-spacing-y;
        padding-right: calc(tokens.$oc-component-button-50-default-spacing-x / 2);
        padding-bottom: tokens.$oc-component-button-50-spacing-y;
        padding-left: tokens.$oc-component-button-50-default-spacing-x;

        > span {
          padding: 0 $oc-component-button-50-span-spacing 0 0;
        }
      }

      &.button--has-icon-left.button--has-icon-right {
        padding: tokens.$oc-component-button-50-spacing-y
          calc(tokens.$oc-component-button-50-default-spacing-x / 2);

        > span {
          padding: 0 $oc-component-button-50-span-spacing;
        }
      }

      > .button__icon {
        height: tokens.$oc-component-button-50-icon-size;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: tokens.$oc-component-button-50-icon-size;

        &--left {
          left: tokens.$oc-component-button-50-icon-spacing-x;
        }

        &--right {
          left: auto;
          right: tokens.$oc-component-button-50-icon-spacing-x;
        }
      }

      &.button--variant-primary {
        font: tokens.$oc-component-button-50-primary-font;
        font-weight: tokens.$oc-component-button-50-primary-font-weight;
      }

      &.button--variant-secondary {
        font: tokens.$oc-component-button-50-secondary-font;
      }

      &.button--variant-tertiary {
        font: tokens.$oc-component-button-50-tertiary-font;
      }

      &:disabled {
        font: tokens.$oc-component-button-50-disabled-font;
      }
    }

    &--is-loading {
      cursor: progress;
    }
  }

  oc-tooltip-v1 {
    width: 100%;
  }

  .tooltip--fit-content {
    width: fit-content;
  }
</style>
